<template>
  <div>
    <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
      <q-chip
        v-for="(item, index) in $comm.inspectIbmTagItems(props.row, tableKeys)"
        :key="index"
        outline square
        :color="item.color"
        :clickable="true"
        text-color="white"
        style="margin-bottom:4px !important;"
        @click="openImprPop(item, props.row)">
        {{item.title}}
        <c-assess-tooltip 
          :ibmTooltip="item.ibmTooltip"
          :ibmClassCd="item.ibmClassCd"
        />
      </q-chip>
    </template>
    <template v-else>
    </template>
    <div v-if="inputEditable && props.row.editFlag !== 'C'">
      <q-btn-group outline class="ColumInnerBtnGroup">
        <q-btn
          icon="add"
          color="red-6"
          text-color="white"
          class="ColumInnerBtn"
          align="center"
          @click.prevent="innerBtnClicked(props, '1')">
          <q-tooltip>
            <!-- 개선요청등록 -->
            {{$language('개선요청등록')}}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="isImmShow"
          icon="add"
          color="light-blue"
          text-color="white"
          class="ColumInnerBtn"
          align="center"
          @click.prevent="innerBtnClicked(props, '2')">
          <q-tooltip>
            <!-- 즉시조치등록 -->
            {{$language('즉시조치등록')}}
          </q-tooltip>
        </q-btn>
      </q-btn-group>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */

export default {
  name: 'tableImpr',

  props: {
    col: {
      type: Object,
      default: function() {
        return {
          taskClassCd: '',
          taskKey: '',
        }
      }
    },
    props: {
      type: Object,
      default: function() {
        return { }
      }
    },
    isImmShow: {
      type: Boolean,
      default: false
    },
    inputEditable: {
      type: Boolean,
      default: false
    },
    requestContentsCols: {
      type: Array,
      default: function() {
        return []
      }
    },
    tableKeys: {
      type: Array,
      default: function() {
        return []
      }
    },
    tableKey: {
      type: String,
      default: ''
    },
    vendorCd: {
      type: String,
      default: ''
    },
    ibmTaskTypeCd: {
      type: String,
      default: ''
    },
    ibmTaskUnderTypeCd: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      requestImprRow: null,
      editable: true,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    openImprPop(data, row) {
      this.requestImprRow = row
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
            this.$set(this.requestImprRow, 'tableKeys', splitTooltip.join('│'))
          }
        }
        this.$emit('imprChange')
      }
    },
    innerBtnClicked(props, btn) {
      this.requestImprRow = props.row
      let requestContents = '개선';
      let _tablekey = '';
      if (this.requestContentsCols && this.requestContentsCols.length > 0) {
        requestContents = this.requestContentsCols.map(col => {
          return props.row[col]
        }).join(' / ')
      }
      if (this.tableKeys && this.tableKeys.length > 0) {
        _tablekey = this.tableKeys.map(col => {
          return props.row[col]
        }).join('/')
      }
      if (btn === '1') {
        this.popupOptions.title = '개선요청';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: this.tableKeys.length > 0 ? _tablekey : props.row[this.tableKey],
          ibmTaskTypeCd: this.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.ibmTaskUnderTypeCd,
          vendorCd: this.vendorCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          requestContents: requestContents,
          relationTableKey: this.tableKeys.length > 0 ? _tablekey : props.row[this.tableKey],
          ibmTaskTypeCd: this.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: this.ibmTaskUnderTypeCd,
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      }
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.col1) {
        let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
        if (sData.indexOf(data.col1.sopImprovementId) === -1) {
          this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
            this.requestImprRow.sopImprovementIds + ',' + data.col1.sopImprovementId : data.col1.sopImprovementId
          this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
            this.requestImprRow.ibmStepNames + ',' + data.col1.ibmStepName : data.col1.ibmStepName
          this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
            this.requestImprRow.ibmClassCds + ',' + data.col1.ibmClassCd : data.col1.ibmClassCd
          this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
            this.requestImprRow.ibmTooltip + '│' + data.col1.ibmTooltip : data.col1.ibmTooltip
          this.requestImprRow.tableKeys = this.requestImprRow.tableKeys ? 
            this.requestImprRow.tableKeys + '│' + data.col1.tableKeys : data.col1.tableKeys
        }
        this.$emit('imprChange')
      }
    },
  }
};
</script>